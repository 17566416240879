import ErrorSVG from "images/404/four-zero-four.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./FourZeroFour.styles";
import { FourZeroFourProps as Props } from "./FourZeroFour.types";
import Button from "components/global/Button/Button";

const FourZeroFour: React.FC<Props> = props => {
  const router = useRouter();

  return (
    <Styles className="FourZeroFour">
      <main className="FourZeroFour__main">
        <div className="FourZeroFour__icon">
          <ErrorSVG />
        </div>
        <p className="FourZeroFour__info">Error 404</p>
        <p className="FourZeroFour__description">¡Página no encontrada!</p>
        <Button
          mode="PRIMARY"
          className="FourZeroFour__button"
          onClick={() => router.push("/")}
        >
          Ir a inicio
        </Button>
      </main>
    </Styles>
  );
};

FourZeroFour.defaultProps = {};

export default FourZeroFour;
